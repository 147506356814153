import axios from "axios";
import { BASE_URL } from "../common/consts";

const baseEndpoint = "api/ai";
const outline = "outline";
const competition = "clear-headings";
const keywords = "generate-keywords";
const addContent = "write-article";

export const getArticleOutline = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${outline}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


export const getCompetitionHeadings = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${competition}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getHeadingKeywords = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${keywords}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getMoreContent = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${addContent}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
