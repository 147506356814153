import React from 'react';
import {WriterIcon} from '../../UI_utils/SVGIcons'
import './PreviousArticle.css'
import { useHistory } from "react-router-dom";
import { getGlobalState } from "../../globals";


const PreviousArticle = ({dashboard}) => {
    const history = useHistory();
    const siteId = getGlobalState('site_id');

    const navigateUserToArticle = () => {
        const path = `/article/${dashboard[0].id}?site_id=${siteId}`;
        history.push(path);
    }
   return(
       <div className="previous-article__container">
           <div className="previous-article" onClick={navigateUserToArticle}>
                <p>Continue working on previous article</p>
                <WriterIcon/>
           </div>
        </div>
   )
}

export default PreviousArticle