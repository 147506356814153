import React, { useEffect, useMemo, useState } from 'react';
import EditableText from './EditableText';
import './ListItem.css';
import { BlackTooltip } from '../UITooltips/UITooltip';
import HeadingSettings from './HeadingSettings';
import ListItemKeywords from './ListItemKeywords';
import { useQuery } from 'react-query';
import { Queries } from '../../common/consts';
import { requestHeadingKeywords, requestMoreContent } from '../../services/ai-outline';
import useArticleId from '../../common/hooks/useArticleId';
import { getSelectedLanguage } from '../../reducers/articlesLanguage';
import { useSelector } from 'react-redux';

const headingOptions = [
  { id: 2, value: 'h2', label: 'Use as H2' },
  { id: 3, value: 'h3', label: 'Use as H3' },
  { id: 4, value: 'h4', label: 'Use as H4' },
  { id: 5, value: 'h5', label: 'Use as H5' },
  { id: 6, value: 'remove', label: 'Remove' },
];

const ListItem = ({ item, onUpdateData, onRemoveItem, list }) => {
  const { articleId } = useArticleId();
  const [fetchKeywords, setFetchKeywords] = useState(false);
  const [fetchMoreData, setFetchMoreData] = useState(false);
  const [currentHeadings, setCurrentHeadings] = useState([]);
  const selectedLanguage = useSelector(getSelectedLanguage)
 
  const { data: keywordsData } = useQuery(
    [
      Queries.HEADING_KEYWORDS, // unique query key
      { id: articleId, heading: item.name, lang: selectedLanguage.value }, // query params
    ],
    requestHeadingKeywords, // query function,
    {
      enabled: fetchKeywords, // only run when fetchKeywords is true
      staleTime: 0, // don't use stale data
      cacheTime: 0, // don't use cache data
    }
  );

  const { data: contentData } = useQuery(
    [
      Queries.CONTENT_DATA, // unique query key
      { id: articleId, heading: currentHeadings?.join(", "), keywords: item.keywords?.join(', ') }, // query params
    ],
    requestMoreContent, // query function,
    {
      enabled: fetchMoreData, // only run when fetchMoreData is true
      staleTime: 0, // don't use stale data
      cacheTime: 0, // don't use cache data
    }
  );

  const onUpdateHeading = (value) => {
    if (value === 'remove') {
      onRemoveItem(item);
    } else {
      const itemWithHeading = { ...item, role: value };
      onUpdateData(itemWithHeading);
    }
  };

  const moreData = () => {
    const selectedIdx = list.findIndex(parsedItem => parsedItem.id === item.id);
    const filteredItems = list.slice(selectedIdx + 1).reduce((result, parsedItem) => {
      if (parsedItem.role !== 'h2') {
        return [...result, parsedItem.name];
      }
      return result;
    }, []);

    const headings = [item.name, ...filteredItems];
    // console.log({headings});
    setCurrentHeadings(headings);
    setFetchMoreData(true);
  };

  const onEditContent = (id, value,) => {
    const newItem = { ...item, [id]: value };
    onUpdateData(newItem);
  };

  const onAddKeywords = () => {
    setFetchKeywords(true);
  };

  const onUpdateItemWithKeywords = (keywords) => {
    const itemWithKeywords = { ...item, keywords };
    onUpdateData(itemWithKeywords);
  };

  useEffect(() => {
    if (keywordsData) {
      const itemWithKeywords = { ...item, keywords: keywordsData.data };
      onUpdateData(itemWithKeywords);
      setFetchKeywords(false);
    }
  }, [keywordsData]);

  useEffect(() => {
    if (contentData) {
      const itemWithMoreData = { ...item, content: contentData.data };
      onUpdateData(itemWithMoreData);
      setFetchMoreData(false);
    }
  }, [contentData]);

  
  // console.log(item.name);

  const customisedOptions = useMemo(() => {
    const filteredOptions = headingOptions.reduce((acc, option) => {
      if (option.value !== item.role) {
        acc.push(option);
      }
      return acc;
    }, []);
    return filteredOptions;
  }, [headingOptions, item.role]);

  const getTooltipTitle = () => {
    if (item.role === 'h2') {
      if (item.keywords.length !== 0) {
        return 'Add more data for this heading';
      } else {
        return 'Add keywords for this heading before completing this action';
      }
    }
    if (item.role !== 'h2') {
      const selectedIdx = list.findIndex(parsedItem => parsedItem.id === item.id);
      if (selectedIdx >= 1) { // Ensure selectedIdx is at least 1 to avoid accessing an out-of-bounds index
        const parentWithRoleH2 = list.slice(0, selectedIdx).reverse().find(item => item.role === 'h2');
        console.log("Parent with role h2 is:", parentWithRoleH2);
        if (parentWithRoleH2.content) {
          return 'Content has already been added for this parent heading, are you sure you want to add more?';
        } else {
          return 'Add more data for this heading'; 
        }
      } else {
        return 'Add more data for this heading'; 
      }
    }
  };

  return (
    <div className='list-item'>
      <div className='list-item-content'>
        <EditableText text={item.name} id="name" onChange={onEditContent} />
        <div className='list-item-actions'>
          <BlackTooltip
            arrow
            placement="top"
            title="Add keywords for this heading"
          >
            <div className='direct-action-btn' onClick={onAddKeywords}>Keywords</div>
          </BlackTooltip>
          <BlackTooltip
            arrow
            placement="top"
            title={getTooltipTitle()}
          >
            <div className={`${item.keywords.length === 0 ? `direct-action-btn-disabled` : `direct-action-btn`} `} onClick={item.keywords.length !== 0 ? moreData : null}>AI Content</div>
          </BlackTooltip>
          <HeadingSettings item={item} options={customisedOptions} onSelect={onUpdateHeading} />
        </div>
      </div>
      {item.content && <EditableText text={item.content} id="content" onChange={onEditContent} />}
      {item.keywords.length !== 0 && <ListItemKeywords keywords={item.keywords} onUpdateItemWithKeywords={onUpdateItemWithKeywords} />}

    </div>
  );
};

export default ListItem;
