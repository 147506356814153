/* if category percent goes higher than max limit, we set the value to 100
   and stop updating the graph - this means they reach their goal */
import {formatText} from "../../utils/topicCoverage";
import {setTitleCoveragePerc} from "../../reducers/titleOptimization";
import {setHeadingCoveragePerc} from "../../reducers/headingsOptimization";

export const getCategoryValue = (categoryPercent) => {
    const perc = categoryPercent || 0;
    const value = perc <= 100 ? perc : 100;

    return value;
};


// Function to calculate coverage and update the state
export const calculateCoverageAndDispatch = (coverageArray, parsedHtmlData, stateSetter, dispatch, stopWords = []) => {
    if(!coverageArray || typeof parsedHtmlData != 'string') return

    const nonBroadKeywords = coverageArray

    const totalSV = nonBroadKeywords.map(keyword => keyword.search_volume).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const usedSV = nonBroadKeywords.map(keyword => {
        if(keyword.variations) {
            const containsVariation = keyword.variations.some(variation => {
                return variation.split(" ").every(kw => {
                    return formatText(parsedHtmlData, stopWords).indexOf(kw) > -1 ? 1 : 0
                })
                // return formatText(parsedHtmlData, stopWords).indexOf(variation) > -1 ? 1 : 0
            });

            return containsVariation ? keyword.search_volume : 0
        } else {
            const kw = keyword.keyword || keyword.name;
            return formatText(parsedHtmlData, stopWords).indexOf(kw) > -1 ? keyword.search_volume : 0
        }
    }).reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    const matchingPerc = (usedSV * 100) / totalSV || 0;
    const matchingPercCap = getCategoryValue(matchingPerc)
    dispatch(stateSetter(Math.round(matchingPercCap)));
}

// Function to calculate and dispatch title coverage
export const calculateAndDispatchTitleCoverage = (titleCoverage, parsedHtmlData, dispatch, stopWords = []) => {
    calculateCoverageAndDispatch(titleCoverage, parsedHtmlData.headings1, setTitleCoveragePerc, dispatch, stopWords);
}

// Function to calculate and dispatch heading coverage
// export const calculateAndDispatchHeadingCoverage = (headingCoverage, parsedHtmlData, dispatch, stopWords = []) => {
//     calculateCoverageAndDispatch(headingCoverage, parsedHtmlData.headings, setHeadingCoveragePerc, dispatch, stopWords);
// }

// Main function to calculate heading and title coverage
export const calculateHeadingAndTitleCoverage = (parsedHtmlData, titleCoverage, headingCoverage, dispatch, stopWords = []) => {
    calculateAndDispatchTitleCoverage(titleCoverage, parsedHtmlData, dispatch, stopWords);
    // calculateAndDispatchHeadingCoverage(headingCoverage, parsedHtmlData, dispatch, stopWords);
};