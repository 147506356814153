// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../_global-vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-error-message {\n  font-family: \"Inter UI\";\n  font-size: 12px;\n  color: var(--color-error-input);\n}\n\n.form-success-message {\n  font-family: \"Inter UI\";\n  font-size: 14px;\n  color: var(--color-success-input);\n}\n", "",{"version":3,"sources":["webpack://./src/Components/FormErrorMessage.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,iCAAiC;AACnC","sourcesContent":["@import \"../_global-vars.css\";\n\n.form-error-message {\n  font-family: \"Inter UI\";\n  font-size: 12px;\n  color: var(--color-error-input);\n}\n\n.form-success-message {\n  font-family: \"Inter UI\";\n  font-size: 14px;\n  color: var(--color-success-input);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
