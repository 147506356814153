import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './DraggableList.css';
import ListItem from './ListItem';
import CustomSvgIcon from '../TipTapEditor/CustomSvgIcon';
import { Scrollbars } from "react-custom-scrollbars";
import OutlineTabs from './OutlineTabs';
import Tab1List from './TabsContent/Tab1List';
import TitleWithTag from '../TitleWithTag/TitleWithTag';
import AiTab from './TabsContent/AiTab';
import { requestQuestionsTabData, requestAiTabData, fetchGroupKeywords, fetchGroupContent } from '../../services/ai-outline';
import { Queries } from '../../common/consts';
import useArticleId from '../../common/hooks/useArticleId';
import { useQuery } from 'react-query';
import QuestionsTab from './TabsContent/QuestionsTab';
import { getSelectedLanguage } from '../../reducers/articlesLanguage';
import { useSelector } from 'react-redux';

const DraggableList = ({ leftList, rightList, list3, list4, list5, onExportList }) => {
  const [list, setList] = useState(leftList);
  const [competitionList, setCompetitionList] = useState(rightList);
  const [aiTabList, setAiTabList] = useState(null);
  const [questionsList, setQuestionsList] = useState(null);
  const [listA, setListA] = useState(list3);
  const [listB, setListB] = useState(list4);
  const [listC, setListC] = useState(list5);
  const { articleId } = useArticleId();
  const [isFetchingAi, setIsFetchingAi] = useState(false);
  const [isFetchingQuestions, setIsFetchingQuestions] = useState(false);

  const selectedLanguage = useSelector(getSelectedLanguage);

  // tab controls
  const [activeTab, setActiveTab] = useState(2);

  const { data: aiTabData } = useQuery(
    [
      Queries.AI_RESULTS, // unique query key
      { id: articleId, qId: 2 }, // query params
    ],
    requestAiTabData, // query function,
    {
      enabled: isFetchingAi, // only run when isFetching is true
      staleTime: 0, // don't use stale data
      cacheTime: 0, // don't use cache data
    }
  );

  const { data: questionsTabData } = useQuery(
    [
      Queries.AI_RESULTS, // unique query key
      { id: articleId, qId: 1 }, // query params
    ],
    requestQuestionsTabData, // query function,
    {
      enabled: isFetchingQuestions, // only run when isFetching is true
      staleTime: 0, // don't use stale data
      cacheTime: 0, // don't use cache data
    }
  );

  const onTabClick = useCallback((val) => {
    setActiveTab(val);
  }, [setActiveTab]);

  const onAddNewItem = () => {
    const newItem = { id: Math.random(), name: 'New Item', role: 'h2', content: null, keywords: [], labelColor: '#6E78F7' };

    setList((prevList) => {
      const newList = [...prevList, newItem];
      return newList;
    });

  };

  const onUpdateData = (updatedItem) => {
    setList((prevList) => {
      const newList = prevList.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });

      return newList;
    });
  };

  const onRemoveItem = (item) => {

    setList((prevList) => {
      const newList = prevList.filter((i) => i.id !== item.id);

      return newList;
    });
  };

  const exportList = () => {
    onExportList(list);
  };


  const mappedListWithActions = {
    'droppable-1': { list, setList },
    'droppable-competition': { list: competitionList, setList: setCompetitionList },
    'droppable-ai': { list: aiTabList, setList: setAiTabList },
    'droppable-questions': { list: questionsList, setList: setQuestionsList },
    // 'droppable-5': { list: listB, setList: setListB },
    // Add more droppableIds and corresponding lists here
  };

  const updateList = (droppableId, newList) => {
    if (mappedListWithActions.hasOwnProperty(droppableId)) {
      const { setList } = mappedListWithActions[droppableId];
      setList(newList);
    }
  };

  const onFetchAiTabData = () => {
    console.log("FETCHING AI TAB DATA");
    setIsFetchingAi(true);
  };

  const onFetchQuestionsTabData = () => {
    console.log("FETCHING Questions DATA");
    setIsFetchingQuestions(true);
  };

  
  useEffect(() => {
    if (aiTabData) {
      setAiTabList(aiTabData);
      setIsFetchingAi(false);
    }
  }, [aiTabData]);

  useEffect(() => {
    if (questionsTabData) {
      setQuestionsList(questionsTabData);
      setIsFetchingQuestions(false);
    }
  }, [questionsTabData]);

  // getting all AI data at once
  const fetchGroupData = async () => {
    const promises = list.map(async (item) => {
      const payload = {
        heading: item.name,
        id: articleId,
        lang: selectedLanguage.value,
      };
      const data = await fetchGroupKeywords(payload);
      if (data.data.length > 0) {
        // get content only if we have keywords
        const anotherPayload = {
          id: articleId,
          heading: item.name,
          keywords: data.data.join(', '),
        };
        const content = await fetchGroupContent(anotherPayload);
        return { ...item, keywords: data.data, content: content.data };
      } else {
        return item;
      }
    });
  
    // waiting all data to be fetched before updating the list
    const updatedListWithKeywords = await Promise.all(promises);
    setList(updatedListWithKeywords);
  };

  return (
    <>
      <DragDropContext
        onDragEnd={(param) => {
          const srcI = param.source.index;
          const desI = param.destination?.index;

          // moving in the same list
          if (desI !== null) {
            const droppableId = param.source.droppableId;
            if (mappedListWithActions.hasOwnProperty(droppableId)) {
              const { list } = mappedListWithActions[droppableId];
              const newList = [...list];
              newList.splice(desI, 0, newList.splice(srcI, 1)[0]);
              updateList(droppableId, newList);
            }
          }

          // moving between lists
          if (desI !== null) {
            const { droppableId: sourceId } = param.source;
            const { droppableId: destinationId } = param.destination || {};

            if ((sourceId === 'droppable-1' && destinationId.startsWith('droppable-')) ||
              (sourceId.startsWith('droppable-') && destinationId === 'droppable-1')) {
              const sourceList = mappedListWithActions[sourceId].list;
              const destinationList = mappedListWithActions[destinationId].list;

              const [removed] = sourceList.splice(srcI, 1);
              destinationList.splice(desI, 0, removed);

              updateList(sourceId, [...sourceList]);
              updateList(destinationId, [...destinationList]);
            }
          }
          // end of moving between lists
        }}
      >
        <div className='dnd-left-panel'>
          <Scrollbars>
            <TitleWithTag title='Draft Builder' tagColor='#6E78F7' />
            <div className='left-list' >
              <Droppable droppableId="droppable-1">
                {(provided, _) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {list.map((item, index) => (
                      <div key={item.id} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <Draggable draggableId={"draggable-" + item.id} index={index}>
                          {(provided, snapshot) => {
                            // console.log({ snapshot })
                            const customStyle = {
                              ...provided.draggableProps,
                              style: {
                                ...provided.draggableProps.style,
                                display: "flex",
                                // alignItems: "center",
                                justifyContent: "flex-start",
                                border: "2px solid #f2f2f2",
                                margin: "15px 0",
                                borderRadius: "15px",
                                marginLeft: `${item.role !== 'h2' ? `30px` : `0px`}`,
                                width: snapshot.isDragging ? "850px" : "100%", // defined max-width for the list container (left-list)
                                // background: "yellow",
                                // borderBottom: `1px solid #ccc`
                              }
                            };
                            // console.log(provided.draggableProps)
                            return (
                              <div ref={provided.innerRef} {...customStyle}>
                                <div className='item-corner' style={{ background: item.labelColor }}></div>
                                <div className='item-with-drag-icon' >
                                  <span className='drag-icon' {...provided.dragHandleProps} >
                                    <CustomSvgIcon icon='drag-move-2-fill' />
                                  </span>
                                  {/* <span {...provided.dragHandleProps} style={{ width: "20px", height: "20px", display: "block", background: "black" }}></span> */}
                                  <ListItem item={item} 
                                    onUpdateData={onUpdateData}
                                    onRemoveItem={onRemoveItem} 
                                    list={list}
                                  />
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className='list-actions'>
              <button className='direct-action-btn' onClick={onAddNewItem}>+ Add heading</button>
              <button className='direct-action-btn-inverted' onClick={exportList}>Create AI Draft</button>
              <button className='direct-action-btn-inverted' onClick={fetchGroupData}>Generate all data</button>
            </div>

          </Scrollbars>
        </div>
        <div className='dnd-right-panel'>
          <OutlineTabs onTabClick={onTabClick} activeTab={activeTab} />
          <div className='right-panel-content'>
            <Scrollbars>
              {/* competition tab */}
              {activeTab === 2 && <Tab1List droppableId="droppable-competition" list2={competitionList} />}
              {/* ai tab */}
              {activeTab === 1 && <AiTab droppableId="droppable-ai" listData={aiTabList} onFetchData={onFetchAiTabData} />}
              {/* questions tab */}
              {activeTab === 3 && <QuestionsTab droppableId="droppable-questions" listData={questionsList} onFetchData={onFetchQuestionsTabData} />}
            </Scrollbars>
          </div>
        </div>
      </DragDropContext>
    </>
  );
};

export default DraggableList;