import {
  getTopicData,
  getTopicCoverage,
  setWordAsDifferent,
  setWordAsRemoved,
  getSearchIntentData,
  getSearchVolumeData,
  getEstimatedVisitsData,
  getTitleCoverageData,
  getHeadingCoverageData,
  getTopicCoverageData,
  getRadarChartData,
  getVariationsData,
  setTopicInfo
} from "../api/topic-search";
import {
  transformKeywords,
  setDefaultTopicRules,
  transformData,
  getExpectedTitle,
  sortTopic,
  insertCountableKeyword,
} from "./topic-helpers";
import { getUserToken } from "../utils/userStatus";
import {getGlobalState} from "../globals";

export const searchIntentTopicService = async (topicObj, token) => {
  const response = await getSearchIntentData(topicObj, token);

  return {searchIntent: response}
}
export const estimatedVisitsTopicService = async (topicObj, token) => {
  const response = await getEstimatedVisitsData(topicObj, token);

  return {estimatedVisits: response}
}
export const searchVolumeTopicService = async (topicObj, token) => {
  const response = await getSearchVolumeData(topicObj, token);

  return transformData(response);
}

export const requestTopicSearch = async (topic, token) => {

  const siteId = getGlobalState('site_id');

  const topicData = await getTopicData(
    { keyword: topic, project: siteId },
    token
  );


  const shapeData = transformData(topicData);

  const topicResult = {
    topicData: shapeData,
    error: false,
  };

  return topicResult;
};


export const titleCoverageService = async (topicObj, token) => {
  const response = await getTitleCoverageData(topicObj, token);

  return response
}

export const headingCoverageService = async (topicObj, token) => {
  const response = await getHeadingCoverageData(topicObj, token);

  return response
}

export const topicCoverageService = async (topicObj, token) => {
  const response = await getTopicCoverageData(topicObj, token);

  return response
}

export const radarChartService = async (topicObj, token) => {
  const response = await getRadarChartData(topicObj, token);

  return response
}


export const variationsService = async (topicObj, token) => {
  const response = await getVariationsData(topicObj, token);

  return response?.data || response
}


export const getTopicDetails = async (topic, language, type, token) => {
  const siteId = getGlobalState('site_id');
  const topicDetails = await getTopicCoverage(
    { keyword: topic, project: siteId, type },
    token
  );

  // separate response based on type (if article or description)
  if (type === 0 || type === 2) {
    const {
      radarChart,
      topicDensityCount,
      topicCoverageHigh,
      topicCoverageLow,
      titleCoverage,
      headingCoverage
    } = topicDetails;

    const modifiedRadarChart = {
      ...radarChart,
      topicDensity: radarChart.topicDensity > 1 ? radarChart.topicDensity : Math.round(radarChart.maxWords * 0.009)
    };

    const maxValues = {
      ...modifiedRadarChart,
    };

    const topicRules = setDefaultTopicRules(maxValues);
    const expectedTitle = getExpectedTitle(topicCoverageHigh);

    // sort the array to have the title always as first element
    const sortedTopicCoverage = sortTopic(topicCoverageHigh);

    // insert use_in_text value for keywords, this way we display how many times a keyword is used inside an article,
    // also this counts for topicDensity in radar chart
    const topicCoverageHighCountable = insertCountableKeyword(
      sortedTopicCoverage,
      topicDensityCount,
      modifiedRadarChart.topicDensity,
    );

    const shapedTopicDetails = {
      maxValues,
      rules: { ...topicRules },
      topicCoverageHigh: transformKeywords(topicCoverageHighCountable),
      topicCoverageLow: transformKeywords(topicCoverageLow),
      // topicCoverageHigh: [],
      // topicCoverageLow: [],
      topicDensityCount,
      titleCoverage,
      headingCoverage,
      expectedTitle,
      error: false,
    };

    return shapedTopicDetails;
  } else {
    const { topicCoverage } = topicDetails;
    const expectedTitle = getExpectedTitle(topicCoverage);
    const sortedTopicCoverage = sortTopic(topicCoverage);
    const shapedTopicDetails = {
      topicCoverageHigh: transformKeywords(sortedTopicCoverage),
      expectedTitle,
      error: false,
    };
    return shapedTopicDetails;
  }
};

export const keywordSetAsCompleted = async (keyword, articleId) => {
  const token = getUserToken();

  try {
    await setWordAsDifferent({ keyword, articleId }, token);
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const keywordRemovedByUser = async (keyword, articleId) => {
  const token = getUserToken();

  try {
    await setWordAsRemoved({ keyword, articleId }, token);
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};


export const topicInfo = async (topic, token) => {
  // const token = getUserToken();
  const siteId = getGlobalState('site_id');

  try {
   return await setTopicInfo({ keyword: topic, project: siteId }, token);
  } catch ({ data }) {
      return {
          ...data,
          error: true,
      };
  }
}