// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../_global-vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login {\n  display: flex;\n  flex-direction: column;\n}\n\n.login-form {\n  list-style: none;\n  width: 400px;\n}\n\n.forgot-pass {\n  display: flex;\n  justify-content: flex-end;\n  font-size: 14px;\n}\n\n.sign-up-link {\n  font-size: 14px;\n  color: var(--color-light-text);\n  padding: 10px 0;\n  display: flex;\n  justify-content: center;\n}\n\n.sign-up-link a {\n  margin-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/LoginForm.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,8BAA8B;EAC9B,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["@import '../_global-vars.css';\n\n.login {\n  display: flex;\n  flex-direction: column;\n}\n\n.login-form {\n  list-style: none;\n  width: 400px;\n}\n\n.forgot-pass {\n  display: flex;\n  justify-content: flex-end;\n  font-size: 14px;\n}\n\n.sign-up-link {\n  font-size: 14px;\n  color: var(--color-light-text);\n  padding: 10px 0;\n  display: flex;\n  justify-content: center;\n}\n\n.sign-up-link a {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
