import React, { useEffect } from 'react';
import useArticleId from '../../../common/hooks/useArticleId';
import Tab1List from './Tab1List';

const AiTab = ({ droppableId, onFetchData, listData }) => {
  const { articleId } = useArticleId();
  
  useEffect(() => {
    if (articleId && !listData) {
      onFetchData()
    }
  }, [articleId, listData, onFetchData]);

  if (!listData) return <div>Loading...</div>;

  return (
    <div>
      {listData && (
        <Tab1List droppableId={droppableId} list2={listData} />
      )}
    </div>
  );
};

export default AiTab;