import React from "react";
import { useSelector } from "react-redux";
import { getWordsPercentage } from "../reducers/counters";

import "./CompletionBar.css";

const CompletionBar = () => {
  // wordcount
  const wordsPercentage = useSelector(getWordsPercentage);

  const progressPercDisplayed = Math.round(wordsPercentage);

  const handleProgressColor = (color) => {
    return progressPercDisplayed < 50 ? color : progressPercDisplayed > 80 ? "#66ca95" : "#EECD72";
  };

  return (
    <div className="completion-bar">
      <div
        style={{
          width: `${progressPercDisplayed}%`,
          backgroundColor: `${handleProgressColor("#E898A2")}`,
          height: "6px",
          transition: "all 1s",
        }}
      ></div>
    </div>
  );
};

export default CompletionBar;
