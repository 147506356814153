import React from 'react';
import './OutlineTabs.css';

const OutlineTabs = ({ activeTab, onTabClick }) => {
  return (
    <div className='outline-tab-menu-header'>
      <ul className='outline-tabs-menu'>
      <li  onClick={() => onTabClick(2)} className={activeTab === 2 ? "active" : ""}>
          <div className="menu-icon">
            <span className="competition">
              <img
                src="/images/_competition-icon.svg"
                width="14px"
                height="14px"
                alt="info"
              />
            </span>

            <p>Competition</p>
          </div>
        </li>
        <li  onClick={() => onTabClick(1)} className={activeTab === 1 ? "active" : ""}>
          <div className="menu-icon">
            <span className="guides">
              <img
                src="/images/_ai-icon.svg"
                width="15px"
                height="15px"
                alt="info"
              />
            </span>
            <p>AI </p>
          </div>
        </li>
        <li  onClick={() => onTabClick(3)} className={activeTab === 3 ? "active" : ""}>
          <div className="menu-icon">
            <span className="brief">
              <img
                src="/images/_icon-brief.svg"
                width="12px"
                height="13px"
                alt="info"
              />
            </span>
            <p>Questions</p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default OutlineTabs;