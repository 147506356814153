import React from 'react'
import './editorLoadingScreen.css'
import LoadingBlock from '../LoadingBlock/LoadingBlock'


const EditorLoadingScreen = () => {
    return (
        <div className="editor-loading-container">
            <LoadingBlock width={'30%'} />
            <LoadingBlock />
            <LoadingBlock />
            <LoadingBlock margin={'30px 0 0 0'} />
            <LoadingBlock />
            <LoadingBlock width={'40%'} />
            <LoadingBlock margin={'30px 0 0 0'} />
            <LoadingBlock />
            <LoadingBlock width={'30%'} />
            <LoadingBlock margin={'30px 0 0 0'} />
            <LoadingBlock />
            <LoadingBlock width={'30%'} />
        </div>
    );
}

export default EditorLoadingScreen;