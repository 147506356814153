import React, { useState } from 'react';
import './EditableText.css';

const EditableText = ({ text, onChange, id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentText, setCurrentText] = useState(text);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    setCurrentText(event.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    onChange(id, currentText);
  };

  const returnInputType = () => {
    if (id === 'name') {
      return (
        <input
          type="text"
          value={currentText || ''}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className='dynamic-text'
        />
      );
    } else {
      return (
        <textarea
          value={currentText || ''}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className='dynamic-text-area'
          
          rows={6} // Set the number of rows
        />
      );
    }
  };


  return (
    <div
      onClick={handleTextClick}
      className={id === 'name' ? 'static-text' : 'static-text-area'}
    >
      {isEditing ? (
        returnInputType()
      ) : (
        <div
          style={{
            fontSize: 'inherit',
            fontFamily: 'inherit',
          }}
        >
          {text}
        </div>
      )
      }
    </div >
  );
};

export default EditableText;