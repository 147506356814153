import React, { useState } from 'react';
import './ListItemKeywords.css';
import CustomSvgIcon from '../TipTapEditor/CustomSvgIcon';

const ListItemKeywords = ({ keywords, onUpdateItemWithKeywords }) => {
  const [newKeyword, setNewKeyword] = useState('');
  const [isAddingKeyword, setIsAddingKeyword] = useState(false);

  const handleNewKeywordChange = (e) => {
    setNewKeyword(e.target.value);
  };

  const handleAddKeyword = () => {
    if (newKeyword.trim() !== '') {
      const newKeywords = [...keywords, newKeyword];
      onUpdateItemWithKeywords(newKeywords);
      // Reset the new keyword input value
      setNewKeyword('');
    }
  };

  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleAddKeyword();
      setIsAddingKeyword(false);
    }
  };

  const handleOnBlur = () => {
    handleAddKeyword();
    setIsAddingKeyword(false);
  };

  const handleRemoveKeyword = (key) => {
    const removedKeywords = keywords.filter((item) => item !== key);
    onUpdateItemWithKeywords(removedKeywords);
  };

  return (
    <div className='list-item-keywords'>
      {keywords.map((keyword, index) => (
        <span key={keyword} className='item-list-keyword'>
          {keyword}
          <button className='remove-button' onClick={() => handleRemoveKeyword(keyword)}>X</button>
        </span>
      ))}

      <div className="add-keyword-container">
        {isAddingKeyword ? (
          <input
            type="text"
            className="new-keyword-input"
            value={newKeyword}
            onChange={handleNewKeywordChange}
            onKeyUp={handleOnKeyUp}
            onBlur={handleOnBlur}
          />
        ) : (
          <div className="add-keyword-button" onClick={() => setIsAddingKeyword(true)}>
            <CustomSvgIcon icon='add-box-line' />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListItemKeywords;