import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CustomSvgIcon from '../../TipTapEditor/CustomSvgIcon';
import ListItemStatic from '../ListItemStatic';
import './Tab1List.css';

const Tab1List = ({ droppableId, list2 }) => {
  return (
    <div className='tab-list-wrapper'>
      <Droppable droppableId={droppableId}>
        {(provided, _) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {list2.map((item, index) => (
              <div key={item.id} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <Draggable draggableId={"draggable-" + item.id} index={index}>
                  {(provided, snapshot) => {
                    const customStyle = {
                      ...provided.draggableProps,
                      style: {
                        ...provided.draggableProps.style,
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "flex-start",
                        border: "2px solid #f2f2f2",
                        margin: "15px 0",
                        borderRadius: "10px",
                        // marginLeft: `${item.role !== 'h2' ? `30px` : `0px`}`,
                        // width: "800px", // defined max-width for the list container (left-list)
                        width: snapshot.isDragging ? "600px" : "100%",
                      }
                    };
                    return (
                      <div ref={provided.innerRef} {...customStyle}>
                        <div className='item-corner' style={{ background: item.labelColor, borderTopLeftRadius: '10px', borderBottomLeftRadius: "10px" }}></div>
                        <div className='item-with-drag-icon' {...provided.dragHandleProps} >
                          <span className='drag-icon' >
                            <CustomSvgIcon icon='drag-move-2-fill' />
                          </span>
                          {/* <span {...provided.dragHandleProps} style={{ width: "20px", height: "20px", display: "block", background: "black" }}></span> */}
                          <ListItemStatic item={item} />
                        </div>
                      </div>
                    );
                  }}
                </Draggable>
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default Tab1List;