import React from 'react';
import './OptionButton.css';

const OptionButton = ({
  id: passedId,
  name,
  value,
  text,
  selected,
  ...props
}) => {

  return (
    <button
      className={`option-button option-button--${selected}`}
      type="button"
      {...props}
      aria-pressed={selected}
    >
      {text}
    </button>
  );
};

export default OptionButton;
