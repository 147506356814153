import { getArticleOutline, getCompetitionHeadings, getHeadingKeywords, getMoreContent } from "../api/ai-outline";
import { v4 as uuidv4 } from 'uuid';
import { getUserToken } from "../utils/userStatus";
import { getAIResponse } from "../api/ai-interactions";

export const requestAIOutline = async ({ queryKey }) => {
  const token = getUserToken();
  const data = await getArticleOutline(queryKey[1], token);

  const updatedData = uniqueIdData(data.data, 'outline')
  return updatedData;
};

export const requestCompetitionHeadings = async ({ queryKey }) => {
  const token = getUserToken();
  const data = await getCompetitionHeadings(queryKey[1], token);
  const updatedData = uniqueIdData(data.data, 'competition')
  return updatedData;
};

export const requestAiTabData = async ({ queryKey }) => {
  const token = getUserToken();
  const data = await getAIResponse(queryKey[1], token);
  const updatedData = transformDataForTabs(data.data, 'ai-tab')

  return updatedData;
};

const temp = ['one', 'two', 'three']

export const requestQuestionsTabData = async ({ queryKey }) => {
  const token = getUserToken();
  // const data = await getAIResponse(queryKey[1], token);
  // console.log({ data })
  const updatedData = transformDataForTabs(temp, 'questions-tab')

  console.log({ updatedData })
 
  return updatedData;
};

export const fetchGroupKeywords = async (payload ) => {
  const token = getUserToken();

  const data = await getHeadingKeywords(payload, token);
  return data;
}

export const requestHeadingKeywords = async ({ queryKey }) => {
  const token = getUserToken();

  const data = await getHeadingKeywords(queryKey[1], token);
  return data;
}

export const fetchGroupContent = async (payload ) => {
  const token = getUserToken();

  const data = await getMoreContent(payload, token);
  return data;
}

export const requestMoreContent = async ({ queryKey }) => {
  const token = getUserToken();

  const data = await getMoreContent(queryKey[1], token);
  return data;
}

// assign unique id to each item
const uniqueIdData = (data, type) => data?.map(item => {
  return {
    ...item,
    labelColor: type === 'competition' ? '#f2a63b' : '#6E78F7',
    role: "h2", // default role is "h2
    id: uuidv4(),
  }
})

const transformDataForTabs = (data, type) => data?.map(item => {
  return {
    id: uuidv4(),
    name: item,
    role: "h2", // default role is "h2
    content: null,
    keywords: [],
    labelColor: type === 'ai-tab' ? '#6a5ecc' : '#bdabf3',
  }
})

