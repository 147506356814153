import React, { useState } from 'react';
import './Switcher.css';
import {WhiteTooltip} from "../Components/UITooltips/UITooltip"; // Assuming you have a CSS file for styles
const AppSwitcher = ({ disabled, tooltip, updateState, label = '' , isChecked = false}) => {
    const [localItemState, setLocalItemState] = useState(isChecked);

    const handleClick = () => {
        if (!disabled) {
            setLocalItemState(!localItemState);
            updateState(!localItemState);
        }
    };

    return (
        <div
            className={`app-switcher d-flex ${disabled ? 'disabled cursor-disabled' : ''}`}
        >
            <WhiteTooltip
                placement="bottom"
                title={!tooltip ? '' : <div style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: tooltip }} />}
            >
                <label className="switcher mb-0 d-flex">
                    {label && <span className="switcher-label">{label}</span>}
                    <input
                        className="checkbox"
                        type="checkbox"
                        onChange={handleClick}
                        checked={localItemState}
                        disabled={disabled}
                    />
                    <span className={`checkmark ${disabled ? 'cursor-disabled' : ''}`}></span>
                </label>
            </WhiteTooltip>
        </div>
    );
};

export default AppSwitcher;