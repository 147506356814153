// import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import {
//   loadingTopicData,
//   getTopicResults,
//   isSearchError,
// } from "../reducers/searchTopic";
// import { getApplicationMode } from "../reducers/applicationMode";
// // import LoaderAnimation from "../Shared/LoaderAnimation";
// import SearchTopicComponent from "../Components/SearchTopicComponent";
// import TopicResultComponent from "../Components/TopicResultComponent";
// import SearchLoader from "./SeachLoader/SearchLoader";
//
// const ModalContentSwitcher = ({ onCancel }) => {
//   const [modalPage, setModalPage] = useState(0);
//   const [resultData, setResultData] = useState();
//
//   const isTopicLoading = useSelector(loadingTopicData);
//   const topicData = useSelector(getTopicResults);
//   const error = useSelector(isSearchError);
//   const appMode = useSelector(getApplicationMode);
//
//   useEffect(() => {
//     setResultData(topicData);
//   }, [topicData]);
//
//   const isArticle = appMode === "article" || appMode === "native";
//
//   if (error) {
//     // redirect to login and refresh the state
//     window.location.replace("/login");
//   }
//
//   if (isTopicLoading) {
//     // return <LoaderAnimation content="Retrieving data. Please wait..." />;
//     return <SearchLoader />;
//   }
//
//   if (modalPage === 1 && isArticle && topicData) {
//     return (
//       <TopicResultComponent
//         nextPage={() => setModalPage(0)}
//         onClose={onCancel}
//         data={resultData}
//         appMode={appMode}
//       />
//     );
//   }
//
//   return (
//     <SearchTopicComponent
//       nexPage={() => setModalPage(1)}
//       appMode={appMode}
//       onClose={onCancel}
//     />
//   );
// };
//
// export default ModalContentSwitcher;
