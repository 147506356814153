import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {AUTH_ERR, GENERIC_ERR} from "../common/consts";
import { setApiErrorMessage } from "../reducers/apiErrors";
import {checkToken, syncUser} from "../api/user";
import {setUserToken} from "./userStatus";

const useGenericError = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleUnauthorizedError = async () => {
    const token = await checkToken();

    syncUser()

    if (token?.token) {
      setUserToken(token.token)
      window.location.reload();
    } else {
      setTimeout(async () => await handleUnauthorizedError(), 5000)
    }
  };

  const setGlobalError = async (error) => {
    if (error.status === 401) {
      setTimeout(async () => await handleUnauthorizedError(), 5000)
    } else {
      dispatch(setApiErrorMessage(GENERIC_ERR));
    }
  };

  return { setGlobalError };
};

export default useGenericError;
