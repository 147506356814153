import axios from "axios";
import { BASE_URL } from "../common/consts";

const topics = "api/topics/step1";
const topicsDetails = "api/topics/step2new";
const categoryTopic = "api/topics/category";
const usedDifferent = "api/keywords/different";
const keywordRemoved = "api/keywords/unused";
const topicsInfo ='api/topics/info'

//before step1 and step2
const checkStatus = "api/topics/check";
//step1
const getSearchIntentEndpoint = "api/topics/search-intent";
const getEstimatedVisitsEndpoint = "api/topics/estimated-visits";
const getSearchVolumeEndpoint = "api/topics/search-volume";
//step2
const getTitleCoverageEndpoint = "api/topics/title-coverage";
const getHeadingCoverageEndpoint = "api/topics/heading-coverage";
const getTopicCoverageEndpoint = "api/topics/topic-coverage";
const getRadarChartEndpoint = "api/topics/radar-chart";
const getVariationsEndpoint = "api/topics/variations";



export const checkTopicStatus = (topic, token) =>
  axios
    .post(`${BASE_URL}/${checkStatus}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data, error } }) => data || error)
    .catch((error) => Promise.reject(error.response));

export const getSearchIntentData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${getSearchIntentEndpoint}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


export const getEstimatedVisitsData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${getEstimatedVisitsEndpoint}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


export const getSearchVolumeData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${getSearchVolumeEndpoint}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


export const getTitleCoverageData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${getTitleCoverageEndpoint}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


export const getHeadingCoverageData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${getHeadingCoverageEndpoint}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


export const getTopicCoverageData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${getTopicCoverageEndpoint}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


export const getRadarChartData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${getRadarChartEndpoint}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


export const getVariationsData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${getVariationsEndpoint}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getTopicData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${topics}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getTopicCoverage = (topic, token) =>
  axios
    .post(`${BASE_URL}/${topicsDetails}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getCategoryTopicCoverage = (topic, token) =>
  axios
    .post(`${BASE_URL}/${categoryTopic}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const setWordAsDifferent = (differentKeyword, token) =>
  axios
    .post(`${BASE_URL}/${usedDifferent}`, differentKeyword, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const setWordAsRemoved = (removedKeyword, token) =>
  axios
    .post(`${BASE_URL}/${keywordRemoved}`, removedKeyword, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const setTopicInfo = (topic, token) =>
    axios
        .post(`${BASE_URL}/${topicsInfo}`, topic, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => Promise.reject(error.response));