import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useHistory, useLocation, useParams } from "react-router-dom";
// import { Snackbar } from "@material-ui/core";
// import {
//   getSearchKeywords,
//   getTopicResults,
//   requestTopicDetails,
//   searchTopic,
// } from "../reducers/searchTopic";
// import { getApplicationMode } from "../reducers/applicationMode";
// import HeaderButtons from "./HeaderButtons";
// import { isDemoApplication } from "../reducers/applicationMode";
// import { getUserToken } from "../utils/userStatus";
// import useMount from "../utils/useMount";
// import { getUserProfile, activeUser, getMasterUsers } from "../reducers/user";
// import dashboard, { initDashboardData } from "../reducers/dashboard";
// import { getButtonStatus } from "../reducers/feedbackFlow";
// import {
//   getCurrentArticle,
//   plagiarismScore,
//   setArticleId,
// } from "../reducers/articles";
// import { getStarsNumber } from "../utils/getDecimals";
// import Heap from "react-heap";
//
// import {
//   CLIENTS_DASHBOARD_PATH,
//   CLIENT_BRIEF_PATH,
//   DASHBOARD_PATH,
//   DEFAULT_ARTICLE_ID,
//   GENERIC_ERR,
//   PERFORMANCE_DASHBOARD_PATH,
//   PROJECTS_PERFORMANCE_DASHBOARD_PATH,
//   USER_MASTER,
//   USER_WRITER,
//   AI_OUTLINE_PATH,
// } from "../common/consts";

import "./Header.css";
// import { StarIconEmpty, StarIconFilled } from "./Rating/Icons";
// import { setTopicCoverageData } from "../reducers/topicCoverage";
// import { setEditorData } from "../reducers/textEditor";
// import PackedVersion from "../../package.json";
import { apiErrorMessage, isApiError } from "../reducers/apiErrors";
import useGenericError from "../utils/useGenericError";
// import UserRating from "./UserRating/UserRating";
// import UserCredits from "./UserCredits/UserCredits";
// import { getUserCredits } from "../api/user";
// import { setFeatureFlags } from "../reducers/featureFlags";

const Header = () => {
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const urlParams = useParams();
  const error = useSelector(isApiError);
  // const errorMsg = useSelector(apiErrorMessage);
  // const { search } = useLocation();
  const { setGlobalError } = useGenericError();
  // const topicSearched = useSelector(getSearchKeywords);
  // const visits = useSelector(getTopicResults);
  // const appMode = useSelector(getApplicationMode);
  // // const isDashboardPage = useSelector(dashboardTotalRows);
  // const isDemoApp = useSelector(isDemoApplication);
  // const user = useSelector(activeUser);
  //
  // const actionButtonMessage = useSelector(getButtonStatus);
  // const currentArticle = useSelector(getCurrentArticle);
  //
  // const [currentUser, setCurrentUser] = useState();
  // const [currentUserType, setCurrentUserType] = useState("");
  // const [metadataVersion, setMetadataVersion] = useState(null);
  // const [allVisits, setAllVisits] = useState(null);
  // const [userCredits, setUserCredits] = useState(null);
  //
  // const isArticle = appMode === "article" || appMode === "native";
  // const isDashboardPage =
  //   window.location.pathname.includes(DASHBOARD_PATH) ||
  //   window.location.pathname.includes(CLIENTS_DASHBOARD_PATH) ||
  //   window.location.pathname.includes(PERFORMANCE_DASHBOARD_PATH) ||
  //
  //   window.location.pathname.includes(PROJECTS_PERFORMANCE_DASHBOARD_PATH);
  // const isProjectBriefPage =
  //   window.location.pathname.includes(CLIENT_BRIEF_PATH);
  //
  //   const isOutlineActive =
  //   window.location.pathname.includes(AI_OUTLINE_PATH)
  //
  // const urlTopic = currentArticle && currentArticle.topicUrl;
  // const isDefaultArticleId =
  //   urlParams.id && urlParams.id === DEFAULT_ARTICLE_ID;
  //
  // // useMount(() => {
  // //   if (!isDemoApp) {
  // //     // retrieveInitData();
  // //   }
  // //   // request plagiarism score (status) only if we have an id in the url
  // //   // if (urlParams.id && urlParams.id !== DEFAULT_ARTICLE_ID) {
  // //   //   dispatch(plagiarismScore(urlParams.id));
  // //   // }
  // //   // getting current version from metadata.json file
  // //   // getMetaDataVersion();
  // //   // getAppFeatures();
  // // });
  //
  // const retrieveInitData = async () => {
  //   const token = getUserToken();
  //   dispatch(getUserProfile(token));
  //   dispatch(getMasterUsers(token));
  //   dispatch(initDashboardData(token));
  // };
  //
  useEffect(() => {
    if (error) {
      setGlobalError(error);
    }
  }, [error]);
  //
  // const getMetaDataVersion = async () => {
  //   const result = await fetch("/metadata.json");
  //   const metaVersion = await result.json();
  //   if (metaVersion) {
  //     setMetadataVersion(metaVersion);
  //   }
  // };
  //
  // const getAppFeatures = async () => {
  //   const result = await fetch("/featconfig.json");
  //   const { features } = await result.json();
  //   if (features) {
  //     dispatch(setFeatureFlags(features));
  //   }
  // };
  //
  // useEffect(() => {
  //   if (user) {
  //     setCurrentUser(user);
  //     setCurrentUserType(user.role[0]);
  //   }
  // }, [user]);
  //
  // useEffect(() => {
  //   if (visits && visits.estimatedVisits) {
  //     setAllVisits(visits.estimatedVisits.toString());
  //   }
  // }, [visits]);
  //
  // const sendHeapAnalytics =
  //   process.env.NODE_ENV === "production" && currentUser;
  // // const sendHeapAnalytics = currentUser;
  // // console.log({ user });
  //
  // // const allIndustries =
  // //   currentUser && currentUser.industries.map(({ name }) => name).join(", ");
  //
  // // const displayUserRating = currentUserType === USER_WRITER;
  // const displayUserRating = true;
  //
  // const topicSearchString =
  //   topicSearched || (currentArticle && currentArticle.name);
  //
  // // const redirectToDashboard = () => {
  // //   history.push(DASHBOARD_PATH);
  // //   dispatch(setArticleId(null));
  // //   // reset topic coverage data when coming from eshop
  // //   dispatch(setTopicCoverageData([]));
  // //   // reset editor data to empty
  // //   dispatch(setEditorData(""));
  // //   dispatch(setKeywordsSearch(null));
  // // };
  //
  // // display console info when dev param is in url
  // const devMode = search.includes("dev");
  // const devInfo = `Package version: ${
  //   PackedVersion.version
  // } // Metadata version: ${
  //   metadataVersion && metadataVersion.version
  // }  // Release Date: ${metadataVersion && metadataVersion.date}`;
  //
  // const requestUserCreditsInfo = async () => {
  //   const token = getUserToken();
  //   const result = await getUserCredits(token);
  //   setUserCredits(result);
  // };
  //
  // useEffect(() => {
  //   if (
  //     currentUserType &&
  //     currentUserType !== USER_MASTER &&
  //     !isDefaultArticleId
  //   ) {
  //     requestUserCreditsInfo();
  //   }
  // }, [currentUserType, urlParams.id]);
  //
  // const onRefetchStepTopicDetails = () => {
  //   console.log('refetch')
  //   dispatch(searchTopic(topicSearchString));
  //   setTimeout(() => {
  //     dispatch(requestTopicDetails(topicSearchString));
  //   }, 10000);
  // };
  //
  // const action = !isDashboardPage && (
  //   <button onClick={onRefetchStepTopicDetails}>Refetch Topic Data</button>
  // );

  return <div />;
  // return (
  //   <>
  //     {/* // display info only when using dev param */}
  //     <Snackbar
  //       open={devMode}
  //       anchorOrigin={{ vertical: "top", horizontal: "left" }}
  //       message={devInfo}
  //       action={action}
  //     ></Snackbar>
  //     {/* // end  */}
  //     <header
  //       className={
  //         !isDashboardPage && !isProjectBriefPage
  //           ? "header"
  //           : "dashboard-header"
  //       }
  //     >
  //       {!isDashboardPage && !isProjectBriefPage && (
  //         <div className="header-left">
  //           {topicSearchString && (
  //             <div className="header-info">
  //               <div className="header-info-name">Topic:</div>
  //               <div className="header-info-value">{`"${topicSearchString}"`}</div>
  //             </div>
  //           )}
  //
  //           {allVisits && (
  //             <div className="header-info">
  //               <div className="header-info-name">Opportunity:</div>
  //               <div className="header-info-value">{`${allVisits} visits`}</div>
  //             </div>
  //           )}
  //         </div>
  //       )}
  //
  //       <div className="header-right">
  //         {!isDashboardPage && !isProjectBriefPage && (
  //           <>
  //             <HeaderButtons
  //               buttonMessage={actionButtonMessage}
  //               isArticle={isArticle}
  //               currentArticle={currentArticle}
  //               currentUserType={currentUserType}
  //             />
  //             <div className="header-divider"></div>
  //           </>
  //         )}
  //
  //         {currentUserType !== USER_MASTER && (
  //           <>
  //             {userCredits && (
  //               <div className="header-credits-wrapper">
  //                 <UserCredits credits={userCredits} />
  //               </div>
  //             )}
  //             <div className="header-divider"></div>
  //           </>
  //         )}
  //         <UserRating currentUser={currentUser} />
  //       </div>
  //     </header>
  //     {sendHeapAnalytics && (
  //       <Heap
  //         appId="384379578"
  //         userId={currentUser.email}
  //         userData={{
  //           firstName: currentUser.firstname,
  //           lastName: currentUser.lastname,
  //           education: currentUser.education,
  //         }}
  //       />
  //     )}
  //   </>
  // );
};

export default Header;
