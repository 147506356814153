import React, { useState } from 'react';
import './HeadingSettings.css';
import CustomMenu2 from '../CustomMenu2';

const HeadingSettings = ({ options, onSelect, defaultSelected, item }) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelected);

  const handleSelect = (row, option) => {
    console.log({ row, option })
    // setSelectedValue(option.value);
    onSelect(option.value);
  };

  // console.log({ options, selectedValue, defaultSelected })

  return (
    <div className='item-heading-settings'>
      {/* {options.map((option) => (
        <label key={option.value}>
          <input
            type="radio"
            name="radioButton"
            value={selectedValue}
            checked={selectedValue === option.value}
            onChange={() => handleSelect(option.value)}
            style={{ display: 'none' }} // Hide the default radio button
          />
          <div className={`${selectedValue === option.value ? 'item-heading-icons-active' : 'item-heading-icons'}`}>{option.icon}</div>
        </label>
      ))} */}
      <CustomMenu2 menuOptions={options} row={item} handleRowAction={handleSelect} />
    </div>
  );
};

export default HeadingSettings;