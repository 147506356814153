import React, { useState } from "react";
import OptionButton from "./OptionButton";

import './CustomToggle.css';

const CustomToggle = ({ values, onChange, label }) => {
  const [currentOption, setCurrentOption] = useState(false);

  const selectOption = (value) => {
    setCurrentOption(value);
    onChange(value);
  }
  return (
    <div>
      <div className="toggle-label">
        {label}
      </div>
      <OptionButton
        selected={!currentOption}
        onClick={() => selectOption(false)}
        text={values[0].label}
      />
      <OptionButton
        selected={currentOption}
        onClick={() => selectOption(true)}
        text={values[1].label}
      />
    </div>
  );
};

export default CustomToggle;
