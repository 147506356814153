import React, { useReducer, useState, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import Input from "../Shared/Input";
import Button from "../Shared/Button";

import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from "../utils/validators";
import { formReducer } from "../utils/formReducer";
import FormErrorMessage from "../Components/FormErrorMessage";
import { DASHBOARD_PATH, MULTI_STEP_REGISTER } from "../common/consts";

import "./LoginForm.css";
import useUserHook from "../apiHooks/useUserHook";
import {setUserToken} from "../utils/userStatus";

const LoginForm = () => {
  const history = useHistory();
  const { loginRequestHook } = useUserHook();
  const [errorMessage, setErrorMessage] = useState(null);
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const loginSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const token = await loginRequestHook(formState.inputs);
      if (token) {
        setUserToken(token)
        // login successful
        // history.push(`${HOME_PATH}/${DEFAULT_ARTICLE_ID}`);
        // redirect to dashboard after login
        history.push(`${DASHBOARD_PATH}`);
      }
    } catch (error) {
      setErrorMessage("Incorrect username or password");
    }
  };

  return (
    <div className="login">
      <form className="login-form" onSubmit={loginSubmitHandler}>
        <Input
          id="email"
          element="input"
          type="text"
          label="Email Address"
          validators={[VALIDATOR_EMAIL()]}
          errorText="Please enter a valid email."
          onInput={inputHandler}
        />
        <Input
          id="password"
          element="input"
          label="Password"
          type="password"
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText="Please enter a password longer than 5 characters."
          onInput={inputHandler}
        />
        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        <div className="forgot-pass">
          <Link to="/reset-password">Forgot password?</Link>
        </div>
        <Button type="submit" fullSize disabled={!formState.isValid}>
          Log In
        </Button>
      </form>
      <div className="sign-up-link">
        Not a member? <Link to={MULTI_STEP_REGISTER}> Sign up now</Link>
        {/* Not a member?{" "}
        <a href="https://contero.io/become-a-writer/"> Sign up now</a> */}
      </div>
    </div>
  );
};

export default LoginForm;
